import * as React from 'react';
import { motion } from 'framer-motion';

const loaderVariants = {
  animationOne: {
    x: [-20, 20],
    y: [0, -30],
    transition: {
      x: {
        yoyo: Infinity,
        duration: 0.5,
      },
      y: {
        yoyo: Infinity,
        duration: 0.25,
        ease: 'easeOut',
      },
    },
  },
};

const Loader = ({ text }: { text: string }) => {
  return (
    <div className="container mx-auto">
      <p className="mb-10">{text}</p>
      <motion.div
        className="w-3 h-3 rounded bg-black"
        variants={loaderVariants}
        animate="animationOne"
      ></motion.div>
    </div>
  );
};

export default Loader;
