import * as React from 'react';

const PersistError = () => {
  return (
    <>
      <h1>Es ist ein Fehler aufgetreten!</h1>
    </>
  );
};

export default PersistError;
